import styled from "@emotion/styled";

import { ClearA, ClearButton, Component, Page as Page_ } from "@/atoms";

import { ReactComponent as MAP } from "./svg/map.svg";
import { ReactComponent as INFO } from "./svg/info.svg";

export const Page = styled(Page_)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InfoArea = styled(Page)`
  width: 100%;
  height: 52.5%;
  background-color: #f1f1f1;

  align-items: flex-start;
`;

export const Info: React.FC = () => (
  <Component
    Base={INFO}
    style={({ gw }) => ({
      marginLeft: gw(240),
    })}
  />
);

export const MapArea = styled(Page)`
  width: 100%;
  height: 47.5%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 37px;
`;

export const Map: React.FC = () => (
  <Component
    Base={MAP}
    style={() => ({
      width: "80%",
    })}
  />
);

export const ButtonArea = styled.div`
  position: absolute;
  bottom: 30px;
`;

export const URLButton = styled(ClearA)`
  position: relative;
  padding: 0 1rem;
  font-size: 1.1rem;
  color: #555 !important;
  font-family: "SeoulHangangM";
  font-weight: bold;

  & + & {
    ::before {
      content: "";
      position: absolute;
      left: 0;
      height: 1.1rem;
      border-left: 1px solid #b3b3b3;
    }
  }
`;

export const Button = styled(ClearButton)`
  position: relative;
  padding: 0 1rem;
  font-size: 1.1rem;
  color: #555 !important;
  font-family: "SeoulHangangM";
  font-weight: bold;

  ::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 0;
    height: 1.1rem;
    border-left: 1px solid #b3b3b3;
  }
  ::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 0;
    height: 1.1rem;
    border-left: 1px solid #b3b3b3;
  }
`;
