import React, { useEffect } from "react";
import styled from "@emotion/styled";

import { Component, Page as Page_ } from "@/atoms";

import ImageEffect from "./svg/image_ani.js";
import { ReactComponent as IMAGE } from "./svg/ani_image.svg";
import { ReactComponent as NAME } from "./svg/name.svg";
import { ReactComponent as INFO } from "./svg/info.svg";
import { ReactComponent as Icon_ } from "./svg/icon.svg";
import { useParams } from "react-router-dom";

export const Page: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Component
    Base={styled(Page_)<SizeComponentProps>`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: ${({ gw }) => gw(1020)};
    `}
  >
    {children}
  </Component>
);

export const Image: React.FC = () => {
  const { page } = useParams();

  useEffect(() => {
    const isStart = (page ?? "0") === "0";
    if (isStart) {
      ImageEffect();
    }
  }, [page]);

  return (
    <Component
      Base={IMAGE}
      width={1085}
      style={({ gw }) => ({
        marginLeft: `-${gw(63)}`,
      })}
    />
  );
};

export const Name: React.FC = () => (
  <Component
    Base={NAME}
    style={({ gh }) => ({
      marginTop: gh(80),
    })}
  />
);

export const Info: React.FC = () => (
  <Component
    Base={INFO}
    style={({ gh }) => ({
      marginTop: gh(120),
    })}
  />
);

export const Icon: React.FC = () => (
  <Component
    Base={styled(Icon_)`
      position: absolute;
      right: 0;
      bottom: 0;
      height: auto;
    `}
  />
);
