import { LikeButton } from "./LikeButton";
import { MoreButton } from "./Album/MoreButton";
import {
  ButtonWrap,
  GalleryArea,
  MainPhoto,
  MainPhotoWrap,
  Page,
  Wording,
  WordingArea,
} from "./styeld";
import { useRecoilState, useRecoilValue } from "recoil";
import { dragStatus, infoJson, likeStatusImage } from "@/recoil";
import { useNavigate } from "react-router-dom";

export const PageGallery: React.FC = () => {
  const navigate = useNavigate();
  const info = useRecoilValue(infoJson);
  const [isDrag] = useRecoilState(dragStatus);
  const like = useRecoilValue(
    likeStatusImage(info.album_main.replace(/(\/|\.(png|jpg|jpeg))/g, ""))
  );

  const onClick = () => {
    if (!isDrag) navigate("album");
  };

  return (
    <Page>
      <GalleryArea>
        <MainPhotoWrap>
          <MainPhoto src={info.album_main} onClick={onClick} />
        </MainPhotoWrap>
        <ButtonWrap>
          <LikeButton
            image={info.album_main}
            count={like.count}
            isLiked={like.isLiked}
          />
          <MoreButton />
        </ButtonWrap>
      </GalleryArea>
      <WordingArea>
        <Wording />
      </WordingArea>
    </Page>
  );
};
