import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styled from "@emotion/styled";

import { LikeButton } from "../LikeButton";
import { css } from "@emotion/react";
import { infoJson, likeStatus } from "@/recoil";
import { useRecoilValue } from "recoil";

export const AlbumSlider: React.FC<{ selected?: number }> = ({ selected }) => {
  const settings = {
    speed: 650,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,
    arrows: true,
    dots: false,
    fade: true,
    easing: "ease-in-out",
  };

  const ref = useRef<Slider | null>(null);
  const [isShow, setIsShow] = useState(false);

  const info = useRecoilValue(infoJson);
  const likes = useRecoilValue(likeStatus);

  useEffect(() => {
    if (selected !== undefined) {
      ref.current?.slickGoTo(selected);
      setTimeout(() => setIsShow(true), 100);
    } else {
      setIsShow(false);
    }
  }, [selected]);

  return (
    <StyledSlider
      ref={(slider) => (ref.current = slider)}
      isShow={isShow}
      {...settings}
    >
      {info.album.map((src, idx) => {
        const id = src.replace(/(\/|\.(png|jpg|jpeg))/g, "");
        const { count, isLiked } = likes[id] ?? { count: 0, isLiked: false };

        return (
          <div key={idx}>
            <Img src={src} alt={src} />
            <ButtonWrap>
              <LikeButton image={src} count={count} isLiked={isLiked} />
            </ButtonWrap>
          </div>
        );
      })}
    </StyledSlider>
  );
};

const StyledSlider = styled(Slider)<{ isShow: boolean }>`
  &.slick-slider {
    margin: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    overflow: hidden;
    ${({ isShow }) =>
      !isShow
        ? css`
            position: absolute;
            top: -9999px;
            left: -9999px;
          `
        : null};
  }

  .slick-slide {
    height: auto;
  }

  .slick-arrow {
    margin-top: -22px;
    &.slick-prev {
      left: 10px;
      z-index: 1;
    }
    &.slick-next {
      right: 10px;
      z-index: 1;
    }
  }
`;

const Img = styled.img`
  width: 100%;
`;

const ButtonWrap = styled.div`
  position: relative;
  margin-top: 15px;
  padding-left: 35px;
  height: 22px;
`;
