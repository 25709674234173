import React from "react";
import styled from "@emotion/styled";
import Modal from "react-modal";
import { useRecoilValue } from "recoil";

import { infoJson } from "@/recoil";

import { ReactComponent as Groom0 } from "../svg/groom_0.svg";
import { ReactComponent as Groom1 } from "../svg/groom_1.svg";
import { ReactComponent as Groom2 } from "../svg/groom_2.svg";
import { ReactComponent as Bride0 } from "../svg/bride_0.svg";
import { ReactComponent as Bride1 } from "../svg/bride_1.svg";
import { ReactComponent as Bride2 } from "../svg/bride_2.svg";
import { ReactComponent as HR } from "../svg/hr.svg";
import { TelButton } from "./TelButton";
import { SmsButton } from "./SmsButton";
import { TalkButton } from "./TalkButton";
import { Component, useGwGh } from "@/atoms";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

export const ContactModal: React.FC = () => {
  const navigate = useNavigate();
  const [gw, gh] = useGwGh();
  const info = useRecoilValue(infoJson);
  const customStyles = {
    overlay: {
      backgroundColor: "#00000080",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: `${gh(150)} 0`,
      transform: "translate(-50%, -50%)",
      width: gw(1176),
      border: 0,
      borderRadius: 0,
    },
  };
  const closeModal = () => navigate(-1);

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      style={customStyles}
    >
      <SubTit>
        <Groom0 />
      </SubTit>

      <ButtonWrap>
        <Name>
          <Groom1 />
          <TelButton phone={info.groom.father.phone} />
          <SmsButton phone={info.groom.father.phone} />
          <TalkButton kakao={info.groom.father.kakao} />
        </Name>
      </ButtonWrap>
      <ButtonWrap>
        <Name>
          <Groom2 />
          <TelButton phone={info.groom.mother.phone} />
          <SmsButton phone={info.groom.mother.phone} />
          <TalkButton kakao={info.groom.mother.kakao} />
        </Name>
      </ButtonWrap>

      <Hr />

      <SubTit>
        <Bride0 />
      </SubTit>
      <ButtonWrap>
        <Name>
          <Bride1 />
          <TelButton phone={info.bride.father.phone} />
          <SmsButton phone={info.bride.father.phone} />
          <TalkButton kakao={info.bride.father.kakao} />
        </Name>
      </ButtonWrap>
      <ButtonWrap>
        <Name>
          <Bride2 />
          <TelButton phone={info.bride.mother.phone} />
          <SmsButton phone={info.bride.mother.phone} />
          <TalkButton kakao={info.bride.mother.kakao} />
        </Name>
      </ButtonWrap>
    </Modal>
  );
};

const SubTit: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Component
    Base={styled.div<SizeComponentProps>`
      > svg {
        display: block;
        margin: 0 auto ${({ gh }) => gh(140)};
        width: ${({ gw }) => gw(196)};
        height: auto;
      }
    `}
  >
    {children}
  </Component>
);

const ButtonWrap: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Component
    Base={styled.div<SizeComponentProps>`
      margin: ${({ gh }) => gh(130)} ${({ gw }) => gw(158)} 0;
      padding-bottom: 25px;
      height: 0;
    `}
  >
    {children}
  </Component>
);

const Name: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Component
    Base={styled.div<SizeComponentProps>`
      > svg {
        position: absolute;
        width: ${({ gw }) => gw(232)};
        height: auto;
      }
    `}
  >
    {children}
  </Component>
);

const Hr = () => (
  <Component
    Base={styled(HR)<SizeComponentProps>`
      display: block;
      margin: ${({ gh }) => gh(150)} auto;
      width: 95%;
      height: auto;
    `}
  />
);
