import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { GlobalCss } from "./atoms";
import { Layout } from "./pages";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalCss />
    <RecoilRoot>
      <HashRouter>
        <React.Suspense fallback={<div />}>
          <Routes>
            <Route path="/:page/*" element={<Layout />} />
            <Route path="/" element={<Navigate to="/0" replace />} />
          </Routes>
        </React.Suspense>
      </HashRouter>
    </RecoilRoot>
  </React.StrictMode>
);
