import styled from "@emotion/styled";

import { Component, Page as Page_ } from "@/atoms";

import CalendarEffect from "./svg/carlendar_ani";
import { ReactComponent as DATE } from "./svg/date.svg";
import { ReactComponent as CALENDAR } from "./svg/calendar.svg";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const Page = styled(Page_)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InfoArea = styled(Page)`
  width: 100%;
  height: 47.5%;
  background-color: #fff;
`;

export const Date: React.FC = () => <Component Base={DATE} />;

export const CalendarArea = styled(Page)`
  width: 100%;
  height: 52.5%;
  background-color: #f1f1f1;
`;

export const Calendar: React.FC = () => {
  const { page } = useParams();

  useEffect(() => {
    const isStart = (page ?? "1") === "1";
    if (isStart) {
      CalendarEffect();
    }
  }, [page]);

  return <Component Base={CALENDAR} width={985} />;
};
