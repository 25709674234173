import { dragStatus, windowStatus } from "@/recoil";
import { useCache } from "@/useCache";
import { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { useRecoilState } from "recoil";

import { PageCalendar } from "./Calendar";
import { AlbumModal, PageGallery } from "./Gallery";
import { Guide } from "./Guide";
import { ContactModal, PageInfo } from "./Info";
import { PageMain } from "./Main";
import { PageMap } from "./Map";

export const Layout: React.FC = () => {
  const sliderRef = useRef<Slider>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [, setWindow] = useRecoilState(windowStatus);
  const [, setDrag] = useRecoilState(dragStatus);

  const navigate = useNavigate();
  let { page } = useParams();

  useCache().read();

  const settings = {
    infinite: false,
    speed: 650,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    easing: "ease-in-out",
    fade: true,
    beforeChange: (current: number, next: number) => {
      setDrag(true);
      navigate(`/${next}`, {
        replace: true,
      });
    },
    afterChange: () => {
      setDrag(false);
    },
  };

  useEffect(() => {
    const set = () =>
      setWindow({
        width: ref.current?.clientWidth ?? 1,
        height: ref.current?.clientHeight ?? 1,
      });

    set();
    window
      .matchMedia("only screen and (min-width: 510px)")
      .addEventListener("change", set);
  }, [setWindow]);

  useLayoutEffect(() => {
    sliderRef.current?.slickGoTo(+(page ?? 0));
  }, [sliderRef]);

  return useMemo(
    () => (
      <>
        <div ref={ref} id="inner">
          <Slider ref={sliderRef} {...settings}>
            <PageMain />
            <PageCalendar />
            <PageGallery />
            <PageMap />
            <PageInfo />
          </Slider>
          <Guide />
        </div>
        <Routes>
          <Route path="album" element={<AlbumModal />} />
          <Route path="contact" element={<ContactModal />} />
        </Routes>
      </>
    ),
    /* 의도적으로 re-rendering 방지 */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
