import { infoJson } from "@/recoil";
import { useRecoilValue } from "recoil";
import { AccountButton } from "./AccountButton";
import { ContactButton } from "./Contact";
import { KakaoPayButton } from "./KakaoPayButton";
import {
  BankWarp,
  BrideBank,
  BrideName,
  BrideParentBank,
  BrideTitle,
  ContactArea,
  GroomBank,
  GroomName,
  GroomParentBank,
  GroomTitle,
  InfoArea,
  InfoTitle,
  Name,
  Page,
} from "./styeld";

export const PageInfo: React.FC = () => {
  const info = useRecoilValue(infoJson);

  return (
    <Page>
      <ContactArea>
        <Name />
        <ContactButton />
      </ContactArea>
      <InfoArea>
        <InfoTitle />
        <GroomTitle />
        <BankWarp>
          <GroomParentBank />
          <AccountButton text={info.groom.account} />
        </BankWarp>
        <BankWarp>
          <GroomBank />
          <br />
          <GroomName />
          <KakaoPayButton href={info.groom.kakaoPay} />
          <AccountButton text={info.groom.account2} />
        </BankWarp>

        <BrideTitle />
        <BankWarp>
          <BrideParentBank />
          <AccountButton text={info.bride.account} />
        </BankWarp>
        <BankWarp>
          <BrideBank />
          <br />
          <BrideName />
          <KakaoPayButton href={info.bride.kakaoPay} />
          <AccountButton text={info.bride.account2} />
        </BankWarp>
      </InfoArea>
    </Page>
  );
};
