/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { windowStatus } from "@/recoil";

const baseWidth = 1440;
const baseHeight = 3088;

export function SizeHoc<T>(Component: Styled<T>) {
  return (args: T) => {
    const [window] = useRecoilState(windowStatus);
    const gw = useCallback(
      (window: { width: number }) => (originWidth: number) =>
        `${(originWidth / baseWidth) * window.width}px`,
      []
    );
    const gh = useCallback(
      (window: { height: number }) => (originWidth: number) =>
        `${(originWidth / baseHeight) * window.height}px`,
      []
    );

    return <Component gw={gw(window)} gh={gh(window)} {...args} />;
  };
}

export const useGwGh = () => {
  const [window] = useRecoilState(windowStatus);
  const gw = useCallback(
    (window: { width: number }) => (originWidth: number) =>
      `${(originWidth / baseWidth) * window.width}px`,
    []
  );
  const gh = useCallback(
    (window: { height: number }) => (originWidth: number) =>
      `${(originWidth / baseHeight) * window.height}px`,
    []
  );

  return [gw(window), gh(window)];
};
