import styled from "@emotion/styled";

import { ClearButton, Component, Page as Page_ } from "@/atoms";

import { ReactComponent as WORDING } from "./svg/wording.svg";

export const Page = styled(Page_)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GalleryArea = styled(Page)`
  width: 100%;
  height: 47.5%;
  padding: 30px 0 78px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media only screen and (max-height: 700px) {
    padding: 20px 0 58px;
  }
`;

export const MainPhotoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const MainPhoto = styled(ClearButton)<{ src: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const ButtonWrap: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Component
    Base={styled.div<SizeComponentProps>`
      position: absolute;
      bottom: 30px;
      padding-left: 35px;
      width: 80%;
      height: 22px;

      @media only screen and (max-height: 700px) {
        bottom: 20px;
      }
    `}
  >
    {children}
  </Component>
);

export const WordingArea = styled(Page)`
  width: 100%;
  height: 52.5%;
  background-color: #f1f1f1;
`;

export const Wording: React.FC = () => <Component Base={WORDING} />;
