import styled from "@emotion/styled";

import { ClearA, ClearButton, Component } from "@/atoms";

import { ReactComponent as TEL } from "../svg/icon_tel.svg";

export const TelButton: React.FC<{ phone?: string }> = ({
  phone = "01000000000",
}) =>
  phone ? (
    <ClearA target="_blank" href={`tel:${phone}`}>
      <Tel />
    </ClearA>
  ) : (
    <ClearButton
      style={{ verticalAlign: "text-top" }}
      onClick={() => alert("카카오톡 오픈채팅으로 참여 부탁드리겠습니다.")}
    >
      <Tel />
    </ClearButton>
  );

const Tel = () => (
  <Component
    Base={styled(TEL)`
      position: absolute;
      left: 40%;
    `}
  />
);
