import {
  URLButton,
  Button,
  ButtonArea,
  Info,
  InfoArea,
  Map,
  MapArea,
  Page,
} from "./styeld";

export const PageMap: React.FC = () => (
  <Page>
    <MapArea>
      <Map />
      <ButtonArea>
        <URLButton
          href="https://map.naver.com/v5/entry/place/37537597?placePath=%2Fhome"
          target="_blank"
        >
          네이버 지도
        </URLButton>
        {/* <URLButton
          href="https://map.kakao.com/link/map/27339651"
          target="_blank"
        >
          카카오맵
        </URLButton> */}
        <Button
          onClick={() =>
            Kakao.Navi.start({
              name: "MJ컨벤션",
              x: 126.7984955,
              y: 37.4819407,
              coordType: "wgs84",
            })
          }
        >
          카카오 내비
        </Button>
        <URLButton
          href="tmap://?rGoName=MJ컨벤션&rGoY=37.4819407&rGoX=126.7984955"
          target="_blank"
        >
          티맵
        </URLButton>
      </ButtonArea>
    </MapArea>
    <InfoArea>
      <Info />
    </InfoArea>
  </Page>
);
