import { atom, selector, selectorFamily } from "recoil";
import { RKey } from "./react-app-env.d";
import { v4 as uuidv4 } from "uuid";

const uuidDefault = selector({
  key: "local_uuid",
  get: () => {
    let uuid = localStorage.getItem("invitation-UUID");

    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem("invitation-UUID", uuid);
    }

    return uuid;
  },
});

export const uuid = atom({
  key: "uuid",
  default: uuidDefault,
});

export const infoJson = selector({
  key: RKey.Info,
  get: async (): Promise<Info> => {
    const response = await fetch(`/info.json?${new Date().getTime()}`);
    return response.json();
  },
});

const likeJson = selector({
  key: `async_${RKey.Like}`,
  get: async ({ get }): Promise<Like> => {
    // const response = await fetch(`https://staysharp0.synology.me:8090/likes`, {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Content-Type": "application/json",
    //     UUID: get(uuid),
    //   },
    // });

    // return await response.json();
    return {};
  },
});

export const likeStatus = atom({ key: RKey.Like, default: likeJson });

export const likeStatusImage = selectorFamily({
  key: `likeStatusImage`,
  get:
    (image: string) =>
    ({ get }) => {
      const likes = get(likeStatus);

      return likes[image] ?? { count: 0, isLiked: false, isDone: false };
    },
});

export const windowStatus = atom({
  key: RKey.Window,
  default: {
    width: 1,
    height: 1,
  },
});

export const dragStatus = atom({
  key: RKey.EventDrag,
  default: false,
});
