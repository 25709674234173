import styled from "@emotion/styled";

import { ClearA, Component } from "@/atoms";

import { ReactComponent as TALK } from "../svg/icon_talk.svg";

export const TalkButton: React.FC<{ kakao?: string }> = ({ kakao = "" }) =>
  kakao ? (
    <ClearA target="_blank" href={kakao}>
      <Talk />
    </ClearA>
  ) : null;

const Talk = () => (
  <Component
    Base={styled(TALK)`
      position: absolute;
      left: 77.5%;
    `}
  />
);
