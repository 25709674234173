import { useRecoilValue } from "recoil";
import { infoJson } from "./recoil";

let load = false;
export const useCache = () => {
  const info = useRecoilValue(infoJson);
  const images = Promise.all(
    ["/main.png", info.album_main, ...info.album].map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    )
  );

  return {
    read() {
      if (!load) {
        load = true;
        throw images;
      } else {
        return;
      }
    },
  };
};
