import styled from "@emotion/styled";

import { ClearButton } from "@/atoms";

import heartAni from "./svg/heart_ani.js";
import { ReactComponent as HEART } from "./svg/heart.svg";
import { ReactComponent as HEARTSTART } from "./svg/heartStart.svg";
import { ReactComponent as HEARTEND } from "./svg/heartEnd.svg";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { likeStatus, likeStatusImage, uuid } from "@/recoil";
import React, { useEffect } from "react";
import { css } from "@emotion/react";

export const LikeButton: React.FC<{
  image: string;
  count: number;
  isLiked: boolean;
}> = React.memo(({ image, count, isLiked }) => {
  const id = image.replace(/(\/|\.(png|jpg|jpeg))/g, "");
  const setLikes = useSetRecoilState(likeStatus);
  // const UUID = useRecoilValue(uuid);
  const onClick = async () => {
    // await fetch(`https://staysharp0.synology.me:8090/likes/${id}`, {
    //   method: isLiked ? "DELETE" : "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     UUID,
    //   },
    // });

    setLikes((likes) => ({
      ...likes,
      [id]: {
        count: isLiked ? count - 1 : count + 1,
        isLiked: !isLiked,
        isDone: false,
      },
    }));
  };

  return (
    <Button onClick={onClick}>
      <HeartWithAni id={id} /> 좋아요 {count}개
    </Button>
  );
});

const HeartWithAni: React.FC<{ id: string }> = React.memo(({ id }) => {
  const like = useRecoilValue(likeStatusImage(id));
  const setLikes = useSetRecoilState(likeStatus);

  useEffect(() => {
    if (!like.isDone && like.isLiked) {
      heartAni();
      setTimeout(
        () =>
          setLikes((likes) => ({
            ...likes,
            [id]: { ...like, isDone: true },
          })),
        500
      );
    }
  }, [id, like, like.isLiked, setLikes]);

  return like.isLiked ? (
    !like.isDone ? (
      <HeartStart />
    ) : (
      <HeartEnd />
    )
  ) : (
    <Heart />
  );
});

const heartCSS = css`
  position: relative;
  top: 3px;
  margin-right: 5px;
  width: 23px;
  height: auto;
`;

const Heart = styled(HEART)`
  ${heartCSS}
`;

const HeartStart = styled(HEARTSTART)`
  ${heartCSS}
`;

const HeartEnd = styled(HEARTEND)`
  ${heartCSS}
`;

const Button = styled(ClearButton)`
  position: absolute;
  left: 0;

  font-family: "SeoulHangangM";
  font-size: 1.1rem;
  line-height: 22px;
  color: #595957;
`;
