import styled from "@emotion/styled";

import { ClearA, ClearButton, Component } from "@/atoms";

import { ReactComponent as SMS } from "../svg/icon_sms.svg";

export const SmsButton: React.FC<{ phone?: string }> = ({
  phone = "01000000000",
}) =>
  phone ? (
    <ClearA target="_blank" href={`sms:${phone}`}>
      <Sms />
    </ClearA>
  ) : (
    <ClearButton
      style={{ verticalAlign: "text-top" }}
      onClick={() => alert("카카오톡 오픈채팅으로 참여 부탁드리겠습니다.")}
    >
      <Sms />
    </ClearButton>
  );

const Sms = () => (
  <Component
    Base={styled(SMS)`
      position: absolute;
      margin-top: 3px;
      left: 57.5%;
    `}
  />
);
