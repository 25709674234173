import { ClearA, Component } from "@/atoms";
import { ReactComponent as PAY } from "./svg/info-pay.svg";
import { ReactComponent as PAY_GUIDE } from "./svg/info-pay_guide.svg";

export const KakaoPayButton: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
> = (props) => (
  <span>
    <ClearA
      target="_blank"
      {...props}
      style={{
        position: "relative",
        padding: "1% 2.5%",
      }}
    >
      <Component
        Base={PAY}
        style={() => ({
          position: "relative",
          top: "2.5%",
        })}
      />
    </ClearA>
    <Component
      Base={PAY_GUIDE}
      style={() => ({
        position: "relative",
        top: "-5%",
      })}
    />
  </span>
);
