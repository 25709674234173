/* eslint-disable @typescript-eslint/no-unused-vars */
/// <reference types="react-scripts" />

import React from "react";

export enum RKey {
  Info = "Info",
  Like = "Like",
  Window = "Window",
  EventDrag = "Event:Drag",
}

declare global {
  type px = string;

  interface SizeComponentProps {
    gw: (width: number) => px;
    gh: (width: number) => px;
  }

  type Styled<T> = React.FC<T & SizeComponentProps>;

  interface Info {
    groom: {
      kakaoPay: string;
      account: string;
      account2: string;
      father: {
        phone: string;
        kakao: string;
      };
      mother: {
        phone: string;
        kakao: string;
      };
    };
    bride: {
      kakaoPay: string;
      account: string;
      account2: string;
      father: {
        phone: string;
        kakao: string;
      };
      mother: {
        phone: string;
        kakao: string;
      };
    };
    album_main: string;
    album: string[];
  }

  interface Like {
    [key: string]: { count: number; isLiked: boolean; isDone: boolean };
  }
}
