import styled from "@emotion/styled";

import { ClearButton, Component } from "@/atoms";

import { ReactComponent as MENU } from "../svg/menu_more.svg";
import { useNavigate } from "react-router-dom";
import { dragStatus } from "@/recoil";
import { useRecoilState } from "recoil";

export const MoreButton: React.FC = () => {
  const navigate = useNavigate();
  const [isDrag] = useRecoilState(dragStatus);

  const onClick = () => {
    if (!isDrag) navigate("album");
  };

  return (
    <Button onClick={onClick}>
      <Component Base={MENU} width={264} />
    </Button>
  );
};

const Button = styled(ClearButton)`
  position: absolute;
  top: -6px;
  right: -10px;
  padding: 10px;
`;
