import { useEffect, useRef, useState } from "react";
import { SizeHoc } from "./SizeHoc";

export const Component = SizeHoc<
  React.PropsWithChildren<{
    Base: React.ElementType;
    width?: number;
    style?: (args: SizeComponentProps) => React.CSSProperties;
  }>
>(({ Base, children, gw, gh, style: _style, width, ...props }) => {
  const ref = useRef<HTMLElement>(null);
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (_style) {
      const calc = _style({ gw, gh });
      setStyle((style) => ({ ...style, ...calc }));
    }
  }, [_style, gh, gw]);

  useEffect(() => {
    if (ref.current instanceof SVGSVGElement) {
      const svg = ref.current as SVGSVGElement;

      setStyle((style) => ({
        ...style,
        width: gw(width ?? svg.width.animVal.value),
        height: "auto",
      }));
    }
  }, [gw, ref, width]);

  return (
    <Base ref={ref} gw={gw} gh={gh} style={style} {...props}>
      {children}
    </Base>
  );
});
