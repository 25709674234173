import styled from "@emotion/styled";
import { useCallback } from "react";
import { atom, selector, useRecoilState } from "recoil";
import { ReactComponent as GUIDE } from "./guide.svg";

const guidedAtom = atom({
  key: "isGuided",
  default: localStorage.getItem("invitation-guide") === "true",
});

const guided = selector({
  key: "local_isGuided",
  get: ({ get }) => get(guidedAtom),
  set: ({ set }) => {
    localStorage.setItem("invitation-guide", "true");
    set(guidedAtom, true);
  },
});

export const Guide = () => {
  const [isGuided, setIsguided] = useRecoilState(guided);
  const onMouseDown = useCallback(() => {
    setIsguided(true);
  }, [setIsguided]);

  return !isGuided ? (
    <GuideLayer onMouseDown={onMouseDown}>
      <GUIDE />
    </GuideLayer>
  ) : null;
};

const GuideLayer = styled.div`
  &,
  & svg {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  :before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.65;
    background-color: #595857;
  }
`;
