import { ClearButton, Component, useGwGh } from "@/atoms";

import { ReactComponent as CONTACT } from "../svg/contact.svg";
import { useNavigate } from "react-router-dom";
import { dragStatus } from "@/recoil";
import { useRecoilState } from "recoil";

export const ContactButton: React.FC = () => {
  const [gh] = useGwGh();
  const navigate = useNavigate();
  const [isDrag] = useRecoilState(dragStatus);

  const onClick = () => {
    if (!isDrag) navigate("contact");
  };

  return (
    <>
      <ClearButton style={{ marginTop: gh(137) }} onClick={onClick}>
        <Component Base={CONTACT} />
      </ClearButton>
    </>
  );
};
