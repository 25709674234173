import styled from "@emotion/styled";

import { Component, Page as Page_ } from "@/atoms";

import { ReactComponent as NAME } from "./svg/name.svg";
import { ReactComponent as INFO_TITLE } from "./svg/info-title.svg";
import { ReactComponent as INFO_GROOM } from "./svg/info-groom_parent.svg";
import { ReactComponent as INFO_GROOM_TITLE } from "./svg/info-groom_title.svg";
import { ReactComponent as INFO_GROOM_BANK } from "./svg/info-groom_bank.svg";
import { ReactComponent as INFO_GROOM_NAME } from "./svg/info-groom_name.svg";
import { ReactComponent as INFO_BRIDE } from "./svg/info-bride_parent.svg";
import { ReactComponent as INFO_BRIDE_TITLE } from "./svg/info-bride_title.svg";
import { ReactComponent as INFO_BRIDE_BANK } from "./svg/info-bride_bank.svg";
import { ReactComponent as INFO_BRIDE_NAME } from "./svg/info-bride_name.svg";

export const Page = styled(Page_)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContactArea = styled(Page)`
  width: 100%;
  height: 47.5%;
  background-color: #fff;
`;

export const Name = () => (
  <Component
    Base={styled(NAME)<SizeComponentProps>`
      @media only screen and (max-height: 700px) {
        width: ${({ gw }) => gw(1000)}!important;
      }
    `}
  />
);

export const InfoArea = styled(Page)`
  width: 100%;
  height: 52.5%;
  background-color: #f1f1f1;
`;

export const BankWarp: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Component
    Base={styled.div<SizeComponentProps>`
      position: relative;
      width: ${({ gw }) => gw(1020)};
    `}
  >
    {children}
  </Component>
);

export const InfoTitle: React.FC = () => <Component Base={INFO_TITLE} />;
export const GroomTitle: React.FC = () => (
  <Component Base={INFO_GROOM_TITLE} style={() => ({ marginTop: "25px" })} />
);
export const GroomParentBank: React.FC = () => (
  <Component Base={INFO_GROOM} style={() => ({ marginTop: "5%" })} />
);
export const GroomBank: React.FC = () => (
  <Component Base={INFO_GROOM_BANK} style={() => ({ marginTop: "5%" })} />
);
export const GroomName: React.FC = () => (
  <Component Base={INFO_GROOM_NAME} style={() => ({ marginTop: "2%" })} />
);

export const BrideTitle: React.FC = () => (
  <Component Base={INFO_BRIDE_TITLE} style={() => ({ marginTop: "25px" })} />
);
export const BrideParentBank: React.FC = () => (
  <Component Base={INFO_BRIDE} style={() => ({ marginTop: "5%" })} />
);
export const BrideBank: React.FC = () => (
  <Component Base={INFO_BRIDE_BANK} style={() => ({ marginTop: "5%" })} />
);
export const BrideName: React.FC = () => (
  <Component Base={INFO_BRIDE_NAME} style={() => ({ marginTop: "2%" })} />
);
